.sp-search-panel {
	width: 100%;
	margin: 0;
	padding: 0;
}

#sp-search-form {
	margin: 0;
	padding: 0;
	margin-left: -30px;
}

.sp-search-panel input[type="text"] {
	min-width: 50%;
	width: auto;
	height: 2.5em;
	padding-left: 35px;
	line-height: 2.5em;
}

.sp-search-panel input[type="submit"] {
	width: auto;
	min-width: 60px;
	height: 2.5em;
	line-height: 2.4em;
	background-color: @oebb-red;
	color: @white;
	border:1px solid @oebb-red;
	text-transform: uppercase;
	font-weight: bold;
	position: relative;
	top: 2px;
}
.sp-search-panel input[type="submit"]:focus {
	outline: 1px dotted white;
	outline-offset: -4px;
}
.sp-search-panel input[type="submit"]:active {
	background-color:  @white;

	color: black;
}
.sp-search-panel input[type="submit"]:hover {
	background-color:  @white;

	color: @oebb-red;
}

#sp-search-icon {
	display: inline-block;
	position: relative;
	left: 35px;
	top: 10px;
	font-size: 1.8em;
	color: @black-20;
}

/* search form neu */
.sp-form-panel {
	position: relative;
	z-index: 2;
width: 100%;
min-height: 50px;
background: @black-10;
-webkit-box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.8);
-moz-box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.8);
box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.8);
margin-bottom: 15px;
padding-top: 20px;
}
.sp-form-panel label{
	display:block;
	font-weight: bold;
	color: #000;
	margin-bottom:4px;
	margin-top:4px;
}

.sp-form-panel input[type="submit"] {
	width: auto;
	min-width: 60px;
	height: 2.5em;
	line-height: 2.4em;
	background-color: @oebb-red;
	color: @white;
	border:1px solid @oebb-red;
	text-transform: uppercase;
	font-weight: bold;
	position: relative;
	top: 2px;
}
.sp-form-panel input[type="submit"]:focus {
	outline: 1px dotted white;
	outline-offset: -4px;
}
.sp-form-panel input[type="submit"]:active {
	background-color:  @white;

	color: black;
}
.sp-form-panel input[type="submit"]:hover {
	background-color:  @white;

	color: @oebb-red;
}

.sp-form-panel .row{
	margin-left:0;
	margin-right:0;
	margin-bottom:20px;
	padding-right:0;
}
@media @only-medium-devices {
	.sp-form-panel .row{
		margin-left:10px;
		margin-right:0;
		padding-right:0;
	}
}
@media @only-small-devices {
	.sp-form-panel .row{
		margin-left:10px;
		margin-right:0;
		padding-right:0;

	}
}
.sp-form-panel [class^=col-] {

	padding-right: 0;


}
.sp-form select{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	width:100%;
}
.sp-form select::-ms-expand {
	display: none;
}
.sp-form input[type="text"]{
	width:100%;
}


/* search result page */

.search-filter, .mashup-filter {
	width: 100%;
	min-height: 50px;
	background: @black-10;
	-webkit-box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.8);
	-moz-box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.8);
	box-shadow: inset 0 20px 20px -20px rgba(0, 0, 0, 0.8);
	margin: 15px 0;
	padding: 0 0 40px 0;
}

.mashup-filter {
	padding: 0 0 20px 0;
}

.search-filter .col-1, .search-filter .col-2 {
	padding: 0 15px 0 0;
}
.mashup-filter .col-1, .mashup-filter .col-2 {
	padding-top: 20px;
}
.search-filter .sp-last-col {
	padding: 0 20px 0 0;
}
.search-filter .row, .mashup-filter .row {
	margin-left: 0;
}
.search-filter .row > .col-3, .search-filter .row > .col-4,
.mashup-filter .row > .col-3, .mashup-filter .row > .col-4 {
	margin-left: 0;
	padding-left: 0;
}

.search-resnr {
	font-size: 1.8em;
	font-weight: bold;
	padding-top: 10px;
	padding-bottom: 20px;
	padding-left: 0;
}
.press-mup-filtertext {
	font-size: 1.4em;
	font-weight: bold;
	padding-top: 29px;
	padding-bottom: 0;
	padding-left: 0;
}

.search-criterion {
	margin: 3px 0 0 0;
	padding: 0;
	width: 100%;
	border: 1px solid @black-20;
	height: 34px;
	background: @white;
}

.search-criterion-box {
	width: 100%;
	position: relative;
}

.search-criterion-box:before {
	content: "\e601";
	display: inline-block;
	position: absolute;
	top: 9px;
	right: 6px;
	font: normal normal normal 16px oebb-fonticons;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 16px;
	height: 16px;
	vertical-align: bottom;
	z-index: 1;
	color: #878787;
}

.search-criterion select {
	display: block;
	position: relative;
	z-index: 2;
	width: 100%;
	font-size: 16px;
	padding: 5px;
	outline: 0;
	border: 0;
	border-radius: 0;
	height: 34px;
	color: @black-40;
	background: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.search-simple-text {
	font-weight: bold;
	color: @black;
}

.search-result-item {
	padding: 10px 10px 10px 0;
	margin-bottom: 10px;
	border-bottom: solid 1px @black-20;
}

.search-result-item-title .ic-arrow2-right {
	display: inline-block;
	position: relative;
	top: 2px;
	left: -4.566px;
	font-size: 1em;
	font-weight: bold;
}

.search-result-item-title a {
	font-size: 1em;
	font-weight: bold;
	text-decoration: none;
	color: @oebb-red;
	margin-bottom: 4px;
	display: inherit;
}
.search-result-item-title a:focus,.search-result-item-title a:hover {
	text-decoration: underline;
}

.search-result-item-title a:active {
	  color:black;
}
ul.search-result-list{
  list-style-type: none;
  padding-left:0;
  max-width: 75%;

  @media @medium-devices-and-smaller{
	  max-width: none;
  }
}

ul.search-result-list li{

	padding: 10px 10px 10px 0;
	margin-bottom: 10px;
	border-bottom: solid 1px #CCCCCC;
}
.search-result-item-criterion {
	font-size: 0.8em;
}

.search-result-item-excerpt {
	font-size: 0.857em;
	color: @black-70;
	margin: 5px 0;
}

.search-result-item-url {
	font-size: 0.8em;
	font-weight: bold;
	color: @dark-font;
	font-style: italic;
}

.search-result-company, .search-result-doctype {
	font-style: italic;
	color: @dark-font;
}

.search-result-doctype {
	text-align: right;
}

.search-more-button {
	margin: 20px auto 0 auto;
	text-align: center;
}
@media @only-large-devices {

	#sp-search-form {
		margin-left: -20px;
	}
}
@media @only-large-devices {
	#sp-search-form {
		margin-left: -10px;
	}
}
@media @medium-devices-and-smaller {
	#sp-search-form {
		margin-left: -30px;
	}
}
@media @only-small-devices {
	#sp-search-form {
		margin-left: -10px;
	}

	.sp-search-panel {
		width: 100%;
		margin: 0;
		padding: 5px 0 5px 0;
	}

	.sp-search-panel input[type="text"] {
		min-width: 200px;
		width: 75%;
	}

	.sp-search-panel input[type="submit"] {
		width: 45px;
	}

	.search-filter .sp-last-col {
		padding-right: 15px;
	}
}

#fess-bahnhof-search-results {
	max-width: 75%;

	@media @medium-devices-and-smaller{
		max-width: none;
	}

	ul.search-result-list {
		max-width: none;
	}
}

.mashup-filter input.search-searchtext-input {
	width: 100%;
	border: none;
}

.sp-select-hidden {
	visibility: hidden;
}
